import "./glide";

// Animate on scroll
AOS.init();

// // Header toggle (Vue)
// const header = Vue.createApp({
//   data() {
//     return {
//       navActive: false,
//     };
//   },
//   methods: {
//     toggleNav() {
//       this.navActive = !this.navActive;
//     },
//   },
// });
// header.mount("header");

$(document).ready(function (e) {
  // Nav Dropdown
  $("nav")
    .find("li")
    .mouseenter(function () {
      $(this).children("ul").stop(true, true).fadeIn("100");
      $(this).addClass("selected");
      $(this).mouseleave(function () {
        $(this).children("ul").stop(true, true).fadeOut("100");
        $(this).removeClass("selected");
      });
    });
});

$("#nav-toggle").on("click tap", function () {
  $("nav").toggleClass("active");
});
